import "styles/pages/portfolio.scss";

import React, {useState} from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import PortfolioFull from "../page_components/portfolio/PortfolioFull";

const Portfolio = ({ data }) => {

  const [category, setCategory] = useState("");

  const seo = data.pagePortfolio?.nodes[0]?.seo;
  const portfolio_page_data = data.pagePortfolio?.nodes[0]?.pagePortfolio;
    
  const header_setup = {logo:'logo_black.svg', fontColor:'#000000', navbarColor: '#ffffff'};

    return (
      <Layout seo={seo} header_setup={header_setup}>
        <section className="portfolio">
          <div className="container-fluid">
            <div className="site-container">
              <div className="section-title">{portfolio_page_data.portfolioPageTitle}</div>
              <div className="portfolio__cathegories">
                <span className={`${category === "" ? "active" : ""}`} onClick={() => setCategory("")}>Wszystkie</span>
                <span className={`${category === "Reklamy" ? "active" : ""}`} onClick={() => setCategory("Reklamy")}>Reklamy</span>
                <span className={`${category === "Dokumentalne" ? "active" : ""}`} onClick={() => setCategory("Dokumentalne")}>Dokumentalne</span>
                <span className={`${category === "Osobiste" ? "active" : ""}`} onClick={() => setCategory("Osobiste")}>Osobiste</span>
                <span className={`${category === "Promocyjne" ? "active" : ""}`} onClick={() => setCategory("Promocyjne")}>Promocyjne</span>
                <span className={`${category === "Podróżnicze" ? "active" : ""}`} onClick={() => setCategory("Podróżnicze")}>Podróżnicze</span>
              </div>
            </div>
          </div>
        </section>

        <div className="portfolio__list-wrapper">
          <PortfolioFull portfolioData={portfolio_page_data.portfolioVideos} videosCategory={category} />
        </div>
      </Layout>
  );
};

export const query = graphql`
  {
    pagePortfolio: allWpPage(filter: {databaseId: {eq: 84}}) {
      nodes {
        seo {
          metaDesc
          title
        }
        pagePortfolio {
          portfolioPageTitle
          portfolioVideos {
            ... on WpPortfolioItem {
              id
              categories {
                nodes {
                  name
                }
              }
              portfolio {
                portfolioAdditional
                portfolioDesc
                portfolioTitle
                portfolioVideo
                portfolioImg {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Portfolio;