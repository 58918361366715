import "./styles.scss";

import React, {useState} from "react";

import PortfolioItem from "components/PortfolioItem";

let portfolio_data_list; 

const PortfolioFull = ({ portfolioData, videosCategory }) => {

  const [openList, setOpenList] = useState(false);

  const filtered_data = portfolioData
  .filter(
    (category) => 
    category.categories.nodes[0].name.includes(videosCategory) || 
    category.categories.nodes[0].name.toLowerCase().includes(videosCategory)
  )

  if (openList) {
    portfolio_data_list = filtered_data;
  } else {
    if (filtered_data.length > 8) {
      portfolio_data_list = filtered_data.slice(0, 8);
    } else {
      portfolio_data_list = filtered_data;
    }
  }

  return (
      <section className={`homepage-grid${openList ? " open-list" : ""}`}>
        <div className="container-fluid">
          <div className="site-container">
            <div className="row">
        
              {portfolio_data_list.map((item, index) => {

                  return (
                    <PortfolioItem key={index} item={item} />
                  )
              })}
              <div className={`homepage-grid__col homepage-grid__col__cta${filtered_data.length > 8 ? "" : " d-none"}`} onClick={() => setOpenList(true)}>
                <div className="homepage-grid__cta">wczytaj<br></br> kolejne</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default PortfolioFull;